import "core-js/stable";
import "regenerator-runtime/runtime";
import Headroom from "headroom.js";
import "./app.scss";
import lazySizes from "lazysizes";
import Menu from "./js/Menu";

/* 
  To include modules, import and include them anywhere in your JS. For example, 
  uncommenting the following two lines will force the jquery chunk to be 
  generated. Normally, only code that is actually used will be output as modules.
*/
// import $ from "jquery"
// console.log( $ );

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}
function runActions() {
  Menu(document.querySelectorAll(".mob-nav-btn"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 50,
  });
  headroom.init();
}
